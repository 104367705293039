/*LETRA*/

@font-face {
  font-family: Letra;
  src: url(https://e-client.s3.amazonaws.com/imagenes-tu-mejor-yo/Futura.otf); }

@font-face {
  font-family: letraBold;
  src: url(https://e-client.s3.amazonaws.com/imagenes-tu-mejor-yo/Cocon-RegularTR.ttf); }

$letra: Letra;

$letrabold: letraBold;

/*COLORES*/

$negro:          black;
$negrohover:     #363031;

$gris: #58595B;
$grisclaro:   #f2f2f3;
$grisclaro2:   #e5e5e6;
$grisclaro3:   #d8d8da;
$grisclaro4:   #bebfc1;
$grisobscuro: #3e3f41;

$azul:        #005493;
$azulclaro:   #cce9ff;
$azulobscuro: #003a66;

$rojo:        #9E1C20;
$rojoclaro:   #f7d4d5;
$rojoobscuro: #82171b;

$aqua:        #00A8AB;
$aquaclaro:   #ccfeff;
$aquaobscuro: #007d80;

$naranja:        #DA5E21;
$naranjaclaro:   #f8dfd3;
$naranjaobscuro: #b14d1b;
$naranjacalendario: #f2bfa6;

$rosa: #D0325F;
$rosaclaro: #f6d5df;
$rosaobscuro: #bb2a53;
$blanco: white;

$amarillo: #FCB117;

/*----Otras Variables------*/

$opacity: .8;

$micro: 2px;
$muychico: 5px;
$chico: 10px;
$mediano: 15px;
$grande: 20px;
$muygrande: 25px;
$enorme: 30px;

$tmuychica: 10px;
$tchica: 13px;
$tmediana: 16px;
$tgrande: 20px;
$tmuygrande: 25px;
$tenorme: 35px;

$a: .555;


/*CALENDARIO*/
.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
  background-color: $naranja;
  border: 1px solid $naranja; }

.CalendarDay__selected_span {
  background: $naranjacalendario;
  border: 1px solid $naranja;
  color: $blanco;
  &:hover {
    background: $naranjacalendario;
    border: 1px solid $naranjaobscuro;
    color: $blanco; } }

.CalendarDay__hovered_span {
  background: $naranjacalendario;
  border: 1px solid $naranjaobscuro;
  color: $blanco;
  &:hover {
    background: $naranja;
    border: 1px solid $naranja;
    color: $blanco; } }

.DateInput_input__focused {
  border-bottom: 2px solid $naranja; }

.DateInput_input {
  font-size: 10px;
  padding: 0px 10px 0px;
  @media (min-width: 576px) {
    font-size: 10px; } }

.DateInput {
  width: 60px;
  @media (min-width: 576px) {
    width: 70px; }
  @media (min-width: 768px) {
    width: 65px; }
  @media (min-width: 900px) {
    width: 70px; }
  @media (min-width: 1200px) {
    width: 75px; } }


.paddingCalendario {
  padding-top: $muychico;
  padding-left: $chico;
  @media (min-width: 576px) {
    padding-top: 0px; } }

.claseCalendario {
  @media (max-width: 650px) {
    font-size: 13.5px; } }

.botonAzul.claseCalendario.btn.btn-secondary.active {
  background-color: $azulobscuro; }

.botonNaranja.claseCalendario.btn.btn-secondary.active {
  background-color: $naranjaobscuro; }



/*LETRA*/
.letra {
  font-family: $letrabold; }

body {
  margin: 0;
  padding: 0;
  font-family: $letra; }

/*COLOR LETRA*/
.negro {
  color: $negro; }

.gris {
  color: $gris; }
.grisClaro {
  color: $grisclaro; }
.grisObscuro {
  color: $grisobscuro; }

.azul {
  color: $azul; }
.azulClaro {
  color: $azulclaro; }
.azulObscuro {
  color: $azulobscuro; }

.rojo {
  color: $rojo; }
.rojoClaro {
  color: $rojoclaro; }
.rojoObscuro {
  color: $rojoobscuro; }

.aqua {
  color: $aqua; }
.aquaClaro {
  color: $aquaclaro; }
.aquaObscuro {
  color: $aquaobscuro; }

.naranja {
  color: $naranja; }
.naranjaClaro {
  color: $naranjaclaro; }
.naranjaObscuro {
  color: $naranjaobscuro; }

.rosa {
  color: $rosa; }
.rosaClaro {
  color: $rosaclaro; }
.rosaObscuro {
  color: $rosaobscuro; }

.blanco {
  color: $blanco; }


/*COLOR FONDO*/
.fondoNegro {
  background: $negro; }

.fondoGris {
  background: $gris; }
.fondoGrisClaro {
  background: $grisclaro; }
.fondoGrisClaro2 {
  background: $grisclaro2; }
.fondoGrisClaro3 {
  background: $grisclaro3; }
.fondoGrisClaro4 {
  background: $grisclaro4; }
.fondoGrisObscuro {
  background: $grisobscuro; }

.fondoAzul {
  background: $azul; }
.fondoAzulClaro {
  background: $azulclaro; }
.fondoAzulObscuro {
  background: $azulobscuro; }

.fondoRojo {
  background: $rojo; }
.fondoRojoClaro {
  background: $rojoclaro; }
.fondoRojoObscuro {
  background: $rojoobscuro; }

.fondoAqua {
  background: $aqua; }
.fondoAquaClaro {
  background: $aquaclaro; }
.fondoAquaObscuro {
  background: $aquaobscuro; }

.fondoNaranja {
  background: $naranja; }
.fondoNaranjaClaro {
  background: $naranjaclaro; }
.fondoNaranjaObscuro {
  background: $naranjaobscuro; }

.fondoRosa {
  background: $rosa; }
.fondoRosaClaro {
  background: $rosaclaro; }
.fondoRosaObscuro {
  background: $rosaobscuro; }

.fondoBlanco {
  background: $blanco; }

.fondoAmarillo {
  background: $amarillo; }



/*COLOR LINK  Link sin formato*/
.linkSF {
  text-decoration: none !important; }

.linkNegro {
  a {
    color: $negro;
    text-decoration: none;
    &:hover {
      color: $negrohover; } } }

.linkGris {
    color: $gris;
    text-decoration: none;
    &:hover {
      color: $grisclaro; } }

.linkGrisClaro {
  a {
    color: $grisclaro;
    text-decoration: none;
    &:hover {
      color: $gris; } } }
.linkGrisObscuro {
  a {
    color: $grisobscuro;
    text-decoration: none;
    &:hover {
      color: $gris; } } }

.linkAzul {
  a {
    color: $azul;
    text-decoration: none;
    &:hover {
      color: $azulobscuro; } } }

.linkAzul2 {
  color: $azul;
  &:hover {
    color: $azulobscuro; } }

.linkAzulClaro {
  a {
    color: $azulclaro;
    text-decoration: none;
    &:hover {
          color: $azul; } } }
.linkAzulObscuro {
  a {
    color: $azulobscuro;
    text-decoration: none;
    &:hover {
      color: $azul; } } }

.linkRojo {
  a {
    color: $rojo;
    text-decoration: none;
    &:hover {
      color: $rojoobscuro; } } }

.linkRojoClaro {
  a {
    color: $rojoclaro;
    text-decoration: none;
    &:hover {
      color: $rojo; } } }
.linkRojoObscuro {
  a {
    color: $rojoobscuro;
    text-decoration: none;
    &:hover {
      color: $rojo; } } }

.linkAqua {
  a {
    color: $aqua;
    text-decoration: none;
    &:hover {
      color: $aquaobscuro; } } }
.linkAquaClaro {
  a {
    color: $aquaclaro;
    text-decoration: none;
    &:hover {
      color: $aqua; } } }
.linkAquaObscuro {
  a {
    color: $aquaobscuro;
    text-decoration: none;
    &:hover {
      color: $aqua; } } }

.linkNaranja {
  a {
    color: $naranja;
    text-decoration: none;
    &:hover {
      color: $naranjaobscuro; } } }
.linkNaranjaClaro {
  a {
    color: $naranjaclaro;
    text-decoration: none;
    &:hover {
      color: $naranja; } } }
.linkNaranjaObscuro {
  a {
    color: $naranjaobscuro;
    text-decoration: none;
    &:hover {
      color: $naranja; } } }

.linkRosa {
  a {
    color: $rosa;
    text-decoration: none;
    &:hover {
      color: $rosaobscuro; } } }
.linkRosaClaro {
  a {
    color: $rosaclaro;
    text-decoration: none;
    &:hover {
      color: $rosa; } } }
.linkRosaObscuro {
  a {
    color: $rosaobscuro;
    text-decoration: none;
    &:hover {
      color: $rosa; } } }

.linkBlanco {
  a {
    color: $blanco;
    text-decoration: none;
    &:hover {
      color: $grisclaro; } } }


/*COLOR BOTON - Estilos */
.botonNegro {
  background: $negro;
  border: none;
  color: $blanco;
  font-weight: bold;
  &:active {
    background: $negro;
    opacity: $opacity; }
  &:hover {
    background: $blanco;
    color: $negro; } }
.botonNegro2 {
  background: $negro;
  border: none;
  color: $blanco;
  font-weight: bold;
  &:active {
    background: $negro;
    opacity:  $opacity; }
  &:hover {
    background: $negrohover; } }

.botonGris {
  background: $gris;
  border: none;
  color: $blanco;
  font-weight: bold;
  &:active {
    background: $gris;
    opacity: $opacity; }
  &:hover {
    background: $negro; } }
.botonGrisClaro {
  background: $grisclaro;
  border: none;
  color: $negro;
  font-weight: bold;
  &:active {
    background: $grisclaro;
    opacity: $opacity; }
  &:hover {
    background: $gris;
    color: $blanco; } }

.botonAzul {
  background: $azul;
  border: none;
  color: $blanco;
  font-weight: bold;
  &:active {
    background: $azul;
    opacity: $opacity; }
  &:hover {
    background: $azulobscuro; } }

.botonAzulClaro {
  background: $azulclaro;
  border: none;
  color: $negro;
  font-weight: bold;
  &:active {
    background: $aqua;
    opacity: $opacity; }
  &:hover {
    background: $aquaclaro;
    color: $negro; } }

.botonRojo {
  background: $rojo;
  border: none;
  color: $blanco;
  font-weight: bold;
  &:active {
    background: $rojo;
    opacity: $opacity; }
  &:hover {
    background: $rojoobscuro; } }

.botonRojoClaro {
  background: $rojoclaro;
  border: none;
  color: $negro;
  font-weight: bold;
  &:active {
    background: $aqua;
    opacity: $opacity; }
  &:hover {
    background: $aquaclaro;
    color: $negro; } }

.botonAqua {
  background: $aqua;
  border: none;
  color: $blanco;
  font-weight: bold;
  &:active {
    background: $aqua;
    opacity: $opacity; }
  &:hover {
    background: $aquaobscuro; } }

.botonNaranja {
  background: $naranja;
  border: none;
  color: $blanco;
  font-weight: bold;
  &:active {
    background: $naranja;
    opacity: $opacity; }
  &:hover {
    background: $naranjaobscuro; } }


.botonRosa {
  background: $rosa;
  border: none;
  color: $blanco;
  font-weight: bold;
  &:active {
    background: $rosa;
    opacity: $opacity; }
  &:hover {
    background: $rosaobscuro; } }

.botonTransparente {
  background: transparent;
  border: none;
  color: $negro;
  font-weight: bold;
  &:active {
    background: $blanco;
    opacity: $opacity; }
  &:hover {
    background: transparent;
    color: $blanco; } }

.botonBlanco {
  background: $blanco;
  border: none;
  color: $negro;
  font-weight: bold;
  &:active {
    background: $blanco;
    opacity: $opacity; }
  &:hover {
    background: $rojo;
    color: $blanco; } }

/*azul*/
.botonBlanco1 {
  background: $blanco;
  border: none;
  color: $azul;
  font-weight: bold;
  &:active {
    background: $aquaclaro;
    opacity: $opacity; }
  &:hover {
    background: $aquaclaro;
    color: $aqua; }
  &:focus {
    background: $aquaclaro;
    color: $aqua; } }

/*rojo*/
.botonBlanco3 {
  background: $blanco;
  border: none;
  color: $rojo;
  font-weight: bold;
  &:active {
    background: $blanco;
    opacity: $opacity; }
  &:hover {
    background: $grisclaro;
    color: $rojo; } }

/*aqua*/
.botonBlanco4 {
  background: $blanco;
  border: none;
  color: $aqua;
  font-weight: bold;
  &:active {
    background: $blanco;
    opacity: $opacity; }
  &:hover {
    background: $grisclaro;
    color: $aqua; } }
/*naranja*/
.botonBlanco2 {
  background: $blanco;
  border: none;
  color: $naranja;
  font-weight: bold;
  &:active {
    background: $blanco;
    opacity: $opacity; }
  &:hover {
    background: $grisclaro;
    color: $naranja; } }

.widthbotones {
    width: 205.36px; }

.claseBotonCuadrado {
    width: 42px;
    height: 42px; }

.botonCuadrado {
  height: 150px;
  width: 150px;
  font-size: 20px;
  font-weight: bold; }

.botonCuadrado2 {
  height: 100px;
  width: 100px;
  font-size: 15px;
  font-weight: bold; }

.claseBoton {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 50px; }

.claseBoton1 {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 5px; }

.claseBoton2 {
  padding-top: 0px;
  padding-bottom: 3px;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 5px; }

.claseBoton3 {
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 3px;
  padding-right: 3px;
  border-radius: 0px; }


.botonArchivo {
  border: 2px solid $azul;
  color: $azul;
  background-color: $blanco;
  padding: 4px 15px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold; }

.botonArchivo-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  input[type=file] {
    font-size: 50px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0; } }


/*BORDER*/
.bordeNaranja {
  border: 2px solid $naranja; }

.bordeRojo {
  border: 2px solid $rojo; }

.bordeAzul {
  border: 2px solid $azul; }

.bordeGris {
  border: 2px solid $gris; }

.bordeNegro {
  border: 2px solid $negro; }

.bordeAqua {
  border: 2px solid $aqua; }

.bordeBlanco {
  border: 2px solid $blanco; }

/*WIDTH - HEIGHT - BORDER*/
.h100 {
    height: 100%; }
.w100 {
    width: 100%; }
.w75 {
    width: 75%; }
.w50 {
    width: 50%; }
.circulo {
    border-radius: 50%; }

/*MAYUSCULAS Y MINUSCULAS*/
.minus {
  text-transform: lowercase; }

.mayus {
  text-transform: uppercase; }

.pmayus {
  text-transform: capitalize; }



/*PADDING*/
.pmicro {
  padding: 1px; }
.pmuychico {
  padding: 2px; }
.pchico {
  padding: 5px; }
.pmediano {
  padding: 10px; }
.pgrande {
  padding: 15px; }
.pmuygrande {
  padding: 20px; }
.penorme {
  padding: 30px; }

.parmicro {
  padding-top: $micro; }
.parmuychico {
  padding-top: $muychico; }
.parchico {
  padding-top: $chico; }
.parmediano {
  padding-top: $mediano; }
.pargrande {
  padding-top: $grande; }
.parmuygrande {
  padding-top: $muygrande; }
.parenorme {
  padding-top: $enorme; }

.pabmicro {
  padding-bottom: $micro; }
.pabmuychico {
  padding-bottom: $muychico; }
.pabchico {
  padding-bottom: $chico; }
.pabmediano {
  padding-bottom: $mediano; }
.pabgrande {
  padding-bottom: $grande; }
.pabmuygrande {
  padding-bottom: $muygrande; }
.pabenorme {
  padding-bottom: $enorme; }

.pizmicro {
  padding-left: $micro; }
.pizmuychico {
  padding-left: $muychico; }
.pizchico {
  padding-left: $chico; }
.pizmediano {
  padding-left: $mediano; }
.pizgrande {
  padding-left: $grande; }
.pizmuygrande {
  padding-left: $muygrande; }
.pizenorme {
  padding-left: $enorme; }

.pdemicro {
  padding-right: $micro; }
.pdemuychico {
  padding-right: $muychico; }
.pdechico {
  padding-right: $chico; }
.pdemediano {
  padding-right: $mediano; }
.pdegrande {
  padding-right: $grande; }
.pdemuygrande {
  padding-right: $muygrande; }
.pdeenorme {
  padding-right: $enorme; }

.pladosmicro {
  padding-right: $micro;
  padding-left: $micro; }
.pladosmuychico {
  padding-right: $muychico;
  padding-left: $muychico; }
.pladoschico {
  padding-right: $chico;
  padding-left: $chico; }
.pladosmediano {
  padding-right: $mediano;
  padding-left: $mediano; }
.pladosgrande {
  padding-right: $grande;
  padding-left: $grande; }
.pladosmuygrande {
  padding-right: $muygrande;
  padding-left: $muygrande; }
.pladosenorme {
  padding-right: $enorme;
  padding-left: $enorme; }

.sinpym {
    padding: 0px;
    margin: 0px; }
.sinpymde {
    padding-right: 0px; }
.sinpymiz {
    padding-left: 0px; }
.sinpymar {
    padding-top: 0px;
    margin-top: 0px; }
.sinpymab {
    padding-bottom: 0px;
    margin-bottom: 0px; }
.sinpymlados {
    padding-right: 0px;
    padding-left: 0px; }


/*MARGIN*/
.mmicro {
  margin: 1px; }
.mmuychico {
  margin: 2px; }
.mchico {
  margin: 5px; }
.mmediano {
  margin: 10px; }
.mgrande {
  margin: 15px; }
.mmuygrande {
  margin: 20px; }
.menorme {
  margin: 30px; }

.marmicro {
  margin-top: $micro; }
.marmuychico {
  margin-top: $muychico; }
.marchico {
  margin-top: $chico; }
.marmediano {
  margin-top: $mediano; }
.margrande {
  margin-top: $grande; }
.marmuygrande {
  margin-top: $muygrande; }
.marenorme {
  margin-top: $enorme; }

.mabmicro {
  margin-bottom: $micro; }
.mabmuychico {
  margin-bottom: $muychico; }
.mabchico {
  margin-bottom: $chico; }
.mabmediano {
  margin-bottom: $mediano; }
.mabgrande {
  margin-bottom: $grande; }
.mabmuygrande {
  margin-bottom: $muygrande; }
.mabenorme {
  margin-bottom: $enorme; }

.mizmicro {
  margin-left: $micro; }
.mizmuychico {
  margin-left: $muychico; }
.mizchico {
  margin-left: $chico; }
.mizmediano {
  margin-left: $mediano; }
.mizgrande {
  margin-left: $grande; }
.mizmuygrande {
  margin-left: $muygrande; }
.mizenorme {
  margin-left: $enorme; }

.mdemicro {
  margin-right: $micro; }
.mdemuychico {
  margin-right: $muychico; }
.mdechico {
  margin-right: $chico; }
.mdemediano {
  margin-right: $mediano; }
.mdegrande {
  margin-right: $grande; }
.mdemuygrande {
  margin-right: $muygrande; }
.mdeenorme {
  margin-right: $enorme; }

.mladosmicro {
  margin-right: $micro;
  margin-left: $micro; }
.mladosmuychico {
  margin-right: $muychico;
  margin-left: $muychico; }
.mladoschico {
  margin-right: $chico;
  margin-left: $chico; }
.mladosmediano {
  margin-right: $mediano;
  margin-left: $mediano; }
.mladosgrande {
  margin-right: $grande;
  margin-left: $grande; }
.mladosmuygrande {
  margin-right: $muygrande;
  margin-left: $muygrande; }
.mladosenorme {
  margin-right: $enorme;
  margin-left: $enorme; }

/*LINEA SEPARADORA*/
.lmuychica {
  height: 1px; }
.lchica {
  height: 2px; }
.lmediana {
  height: 3px; }
.lgrande {
  height: 4px; }
.lmuygrande {
  height: 5px; }
.lenorme {
  height: 10px; }


/*TAMAÑO LETRA*/
.tmuychica {
  font-size: $tmuychica; }
.tchica {
  font-size: $tchica; }
.tmediana {
  font-size: $tmediana; }
.tgrande {
  font-size: $tgrande; }
.tmuygrande {
  font-size: $tmuygrande; }
.tenorme {
  font-size: $tenorme; }
.tepica {
  font-size: 60px; }

.tresponsiva {
    font-size: 13px;
    @media (min-width: 650px) {
      font-size: 14px; }
    @media (min-width: 1020px) {
      font-size: 15px; } }


/*FONT WEIGHT*/
.wnormal {
  font-weight: normal; }
.wbold {
  font-weight: bold; }
.wbolder {
  font-weight: bolder; }


/*ALINEAR*/
.centro {
  text-align: center;
  align-items: center; }
.izquierda {
  text-align: left;
  align-items: center; }
.derecha {
  text-align: right;
  align-items: center; }
.justificado {
  text-align: justify;
  text-justify: inter-word; }
.enmedio {
  vertical-align: middle;
  display: inline; }
.abajo {
  vertical-align: bottom; }

/*REDONDEAR*/

.redondo {
  border-radius: 15px; }

.redondoar {
  border-radius: 15px 15px 0px 0px; }

.redondoab {
  border-radius: 0px 0px 15px 15px; }

.redondoiz {
  border-radius: 15px 0px 0px 15px; }

.redondode {
  border-radius: 0px 15px 15px 0px; }

.redondoariz {
  border-radius: 15px 0px 0px 0px; }

.redondoarde {
  border-radius: 0px 15px 0px 0px; }

.redondoabde {
  border-radius: 0px 0px 15px 0px; }

.redondoabiz {
  border-radius: 0px 0px 0px 15px; }


/*INPUTS*/
.letraTamanoChico {
  font-size: 13;
  @media (min-width: 692px) {
    font-size: 15; }
  @media (min-width: 900px) {
    font-size: 16; } }

.letraChicaInput {
  font-size: .8em !important;
  padding: .4em !important;
  height: 2.4em !important;
  @media (min-width: 576x) {
    font-size: .9em !important;
    padding: .5em !important;
    height: 2.45em !important; }
  @media (min-width: 992px) {
    font-size: 1em !important;
    padding: .5em !important;
    height: 2.4em !important; } }

.letraChicaInput2 {
  font-size: .85em !important;
  padding: .5em !important;
  height: 2.5em !important;
  @media (min-width: 692px) {
    font-size: 1em !important;
    padding: .5em !important; } }

.letraAppend {
  font-size: .85em !important;
  height: 2.5em !important;
  @media (min-width: 692px) {
    font-size: 1em !important; } }

.addon {
  font-size: .8em !important;
  height: 2.4em !important;
  @media (min-width: 576x) {
    font-size: .9em !important;
    padding: .5em !important;
    height: 2.45em !important; }
  @media (min-width: 992px) {
    font-size: 1em !important;
    padding: .5em !important; } }


/*MERCADO*/
.tamanoProductoImg {
  width: 75px; }

.tamanoProductoImg2 {
  width: 100px; }

.tamanoProductoImg3 {
  width: 150px;
  @media (min-width: 692px) {
    width: 200px; } }

.logoIconos {
  width: 150px;
  @media (min-width: 692px) {
    width: 250px; }
  @media (min-width: 1300px) {
    width: 280px; } }

.tamanoIconos {
  width: 270px;
  @media (min-width: 325px) {
    width: 300px; }
  @media (min-width: 400px) {
    width: 350px; }
  @media (min-width: 692px) {
    width: 500px; }
  @media (min-width: 1300px) {
    width: 600px; } }

.paddingIconos {
  padding-top: 30px;
  @media (min-width: 325px) {
    padding-top: 40px; }
  @media (min-width: 692px) {
    padding-top: 70px; } }

.fixedTop {
  top: -10px;
  position: absolute; }

.cristinalogo {
  height: 120px;
  padding-top: 10px;
  padding-left: 10px;
  z-index: 9999;
  @media (min-width: 1200px) {
    height: 180px; }
  @media (min-width: 1350px) {
    padding-left: 20px; } }

.cristinalogo2 {
  height: 120px;
  position: absolute;
  top: -10px;
  padding-left: 10px;
  z-index: 9999;
  @media (min-width: 768px) {
    height: 140px; }
  @media (min-width: 1200px) {
    height: 180px; } }

//inicio scroll scrollHorizontal



.scrollHorizontal {
  overflow: hidden;
  white-space: nowrap; }



.hPage {
  vertical-align: middle;
  display: inline-block; }

//menu


//inicio
//chevrons en centro pantalla mediana a grande
#naveg {
  position: absolute;
  margin: 0;
  top: 40%;
  -ms-transform: translateY(-50%);
  transform: translateY(50%); }

//chevrons abajo pantalla chica
#naveg2 {
  position: sticky;
  margin: 0;
  height: 100%;
  bottom: 0px;
  -ms-transform: translateY(-90%);
  transform: translateY(90%); }

.transparente {
  background: rgba(0, 84, 147, 0.7); }


.transparente2 {
  background: rgba(255,255,255, 0.85); }

//card de inicio transparente en centro
#naveg3 {
  position: absolute;
  margin: 0;
  height: 100%;
  top: 1vw;
  -ms-transform: translateY(-19%);
  transform: translateY(19%);
  @media (min-width: 692px) {
    top: 1vw;
    -ms-transform: translateY(-25%);
    transform: translateY(25%); }
  @media (min-width: 900px) {
    top: 1vw;
    -ms-transform: translateY(-20%);
    transform: translateY(20%); }
  @media (min-width: 1367px) {
    top: 1vw;
    -ms-transform: translateY(-29%);
    transform: translateY(29%); } }
//pantalla menosxs 576xs 768sm, 992md, 1200lg

.naveg4 {
  bottom: 100px;
  position: absolute;
  left: 50%;
  margin-left: -15px; }

.chevronInicio {
  color: $blanco;
  opacity: 1;
  &:hover {
    color: $grisclaro;
    opacity: .7; }
  &:focus {
    opacity: 0; } }

.botonInicio {
  font-size: 16px;
  @media (min-width: 692px) {
    font-size: 23px; }
  @media (min-width: 900px) {
    font-size: 28px; } }

.botonRegistrate {
  font-size: 17px !important; }

.textoEpico {
  font-size: 45px;
  @media (min-width: 900px) {
    font-size: 60px; } }


.paddingPantallaEnorme {
  @media (min-width: 1600px) {
    padding-top: 4vw; } }

//animaciones facil
.fadeIn1 {
  animation-name: mymove;
  animation-duration: 3s; }

.fadeIn2 {
  opacity: 0;
  margin-bottom: 5%;
  padding-left: 10%;
  @media (min-width: 420px) {
    padding-left: 15%; }
  @media (min-width: 1800px) {
    padding-left: 19%; } }

.fadeIn3 {
  opacity: 0;
  margin-bottom: 5%;
  padding-left: 10%;
  @media (min-width: 420px) {
    padding-left: 15%; }
  @media (min-width: 1800px) {
    padding-left: 19%; } }

.fadeIn4 {
  opacity: 0;
  margin-bottom: 5%;
  padding-left: 10%;
  @media (min-width: 420px) {
    padding-left: 15%; }
  @media (min-width: 1800px) {
    padding-left: 19%; } }

.fadeIn5 {
  opacity: 0;
  margin-bottom: 5%;
  padding-left: 10%;
  @media (min-width: 420px) {
    padding-left: 15%; }
  @media (min-width: 1800px) {
    padding-left: 19%; } }

.fadeIn6 {
  opacity: 0;
  margin-bottom: 5%;
  padding-left: 10%;
  @media (min-width: 420px) {
    padding-left: 15%; }
  @media (min-width: 1800px) {
    padding-left: 19%; } }


// seccion7 contacto


.borderRBlanco {
  border-right: 1px solid;
  border-color: $blanco; }

.borderLBlanco {
  border-left: 1px solid;
  border-color: $blanco; }

.borderRAqua {
  border-right: 3px solid;
  border-color: $aqua; }

.borderRRojo {
  border-right: 3px solid;
  border-color: $rojo; }

.borderRNaranja {
  border-right: 3px solid;
  border-color: $naranja; }

.borderRBlanco {
  border-right: 3px solid;
  border-color: $blanco; }

.borderRGris {
  border-right: 3px solid;
  border-color: $gris; }

.borderLGris {
  border-left: 3px solid;
  border-color: $gris; }


.muychicaachica {
  font-size: $tmuychica;
  @media (min-width: 576px) {
    font-size: $tchica; }
  @media (min-width: 768px) {
    font-size: $tmuychica; }
  @media (min-width: 992px) {
    font-size: 11px; }
  @media (min-width: 1100px) {
    font-size: 12px; }
  @media (min-width: 1300px) {
    font-size: 13px; } }

.chicaamediana {
  font-size: $tchica;
  @media (min-width: 576px) {
    font-size: $tchica; }
  @media (min-width: 992px) {
    font-size: $tmediana; }
  @media (min-width: 1100px) {
    font-size: $tmediana; }
  @media (min-width: 1300px) {
    font-size: $tmediana; } }

.paddingSociosInicio {
  padding-top: 20px;
  @media (min-width: 768px) {
    padding-top: 0px; } }

.botonCardNaranja {
  color: $naranja;
  font-weight: bold;
  border: 2px solid $naranja;
  font-size: 12px;
  padding-top: 2px;
  padding-bottom: 1px;
  padding-left: 3px;
  padding-right: 3px;
  border-radius: 0px;
  &:hover {
    background-color: $naranjaclaro;
    color: $naranjaobscuro;
    border: 2px solid $naranjaobscuro; }
  &:focus {
    background-color: $naranjaclaro;
    color: $naranjaobscuro;
    border: 2px solid $naranjaobscuro; }
  &:active {
    background-color: $naranjaclaro;
    color: $naranjaobscuro;
    border: 2px solid $naranjaobscuro; } }

.botonCardAzul {
  color: $azul;
  font-weight: bold;
  border: 2px solid $azul;
  font-size: 12px;
  padding-top: 2px;
  padding-bottom: 1px;
  padding-left: 3px;
  padding-right: 3px;
  border-radius: 0px;
  &:hover {
    background-color: $azulclaro;
    color: $azulobscuro;
    border: 2px solid $azulobscuro; }
  &:focus {
    background-color: $azulclaro;
    color: $azulobscuro;
    border: 2px solid $azulobscuro; }
  &:active {
    background-color: $azulclaro;
    color: $azulobscuro;
    border: 2px solid $azulobscuro; } }

.botonCardNaranja2 {
  color: $naranja;
  font-weight: bold;
  border: 0px solid $naranja;
  font-size: 12px;
  padding-top: 2px;
  padding-bottom: 1px;
  padding-left: 3px;
  padding-right: 3px;
  border-radius: 0px;
  &:hover {
    background-color: $naranjaclaro;
    color: $naranjaobscuro;
    border: 0px solid $naranjaobscuro; }
  &:focus {
    background-color: $naranjaclaro;
    color: $naranjaobscuro;
    border: 0px solid $naranjaobscuro; }
  &:active {
    background-color: $naranjaclaro;
    color: $naranjaobscuro;
    border: 0px solid $naranjaobscuro; } }

.letraClientes {
  font-size: 14px;
  margin-top: 2px; }

.tventas {
  font-size: 13px;
  padding-top: 15px;
  @media (min-width: 576px) {
    padding-top: 13px;
    font-size: 20px; }
  @media (min-width: 768px) {
    padding-top: 14px;
    font-size: 16px; }
  @media (min-width: 992px) {
    font-size: 17px; } }

.claseBuscador {
  font-size: $tmuychica;
  @media (min-width: 576px) {
    font-size: $tchica; }
  @media (min-width: 768px) {
    font-size: $tmediana; } }

.paddingBuscadorArea {
  padding-top: 6px;
  @media (min-width: 576px) {
    padding-top: $micro; }
  @media (min-width: 768px) {
    padding-top: $chico; } }

.paddingBuscadorArea2 {
  padding-top: 0px;
  @media (min-width: 576px) {
    padding-top: $micro; }
  @media (min-width: 768px) {
    padding-top: 8px; } }

.paddingTicket {
  @media (min-width: 768px) {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px; } }


.inputClientes {
  font-size: 1.2em !important;
  padding: .4em !important;
  height: 2.45em !important;
  @media (min-width: 992px) {
    font-size: 1em !important;
    padding: .5em !important;
    height: 2.4em !important; } }

.parmanzana {
  padding-top: 0px;
  @media (min-width: 768px) {
    padding-top: $micro; } }

.parPlus {
  padding-top: $muychico;
  @media (min-width: 768px) {
    padding-top: $micro; } }

.letraBuscador {
  font-size: .8em !important;
  padding: .5em !important;
  height: 35px !important;
  @media (min-width: 576px) {
    font-size: 1em !important;
    padding: .5em !important;
    height: 2.45em !important; }
  @media (min-width: 768px) {
    font-size: 1em !important;
    padding: .5em !important;
    height: 2.75em !important; } }

.botonLink {
     background: none !important;
     color: inherit;
     border: none;
     padding: 0 !important;
     font: inherit;
     /*border is optional*/
     border-bottom: none;
     cursor: pointer;
     &:hover {
      color: $negro; } }

.productoLista {
  width: 65px;
  max-height: 65px;
  @media (min-width: 576px) {
    width: 75px;
    max-height: 75px; }
  @media (min-width: 768px) {
    width: 80px;
    max-height: 80px; } }

.tproducto {
  font-size: $tchica;
  @media (min-width: 576px) {
    font-size: 14px; }
  @media (min-width: 768px) {
    font-size: 15px; }
  @media (min-width: 992px) {
    font-size: 16px; } }

.parmicro2 {
  padding-top: 3px; }

.parmicro3 {
  padding-top: 4px; }

.intentoBorder {
  border-bottom: 2px $negro solid;
  border-right: 2px $negro solid;
  border-left: 2px $negro solid;
  border-radius: 0px 0px 5px 5px; }


.par1 {
  padding-top: 1px; }

.par2 {
  padding-top: 2px; }

.tamanoBotonBorrar {
  width: 64.45px;
  height: 36px; }

.claseClientes {
  border-radius: 8px 8px 0px 0px; }

.nocentro {
  text-align: left;
  @media (min-width: 576px) {
    text-align: center; } }

.sinpymizsm {}
@media (min-width: 576px) {
    padding-left: 0px; }

.my-custom-scrollbar {
  position: relative;
  height: 65vh;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  @media (min-width: 480px) {
    height: 400px; } }

.table-wrapper-scroll-y {
  display: block; }

.fondo {
  background-image: url("https://e-client.s3.amazonaws.com/imagenes-tu-mejor-yo/1.jpg");
  background-size: 280%;
  background-repeat: no-repeat;
  @media (min-width: 375px) {
    background-size: 290%;
    background-position: 20% 0%; }
  @media (min-width: 400px) {
    background-size: 285%;
    background-position: 40% 10%; }
  @media (min-width: 512px) {
    background-size: 300%;
    background-position: 40% 50%; }
  @media (min-width: 550px) {
    background-size: 270%; }
  @media (min-width: 600px) {
    background-size: 240%;
    background-position: 40% 20%; }
  @media (min-width: 768px) {
    background-size: 220%;
    background-position: 30% 0%; }
  @media (min-width: 1000px) {
    background-size: 150%;
    background-position: 20% 0%; }
  @media (min-width: 1300px) {
    background-size: 120%;
    background-position: 15% 0%; }
  @media (min-width: 1600px) {
    background-size: 100%; } }

.fondo2 {
  background-image: url("https://e-client.s3.amazonaws.com/imagenes-tu-mejor-yo/5.jpg");
  background-size: 330%;
  background-repeat: no-repeat;
  @media (min-width: 375px) {
    background-position: 20% 0%; }
  @media (min-width: 400px) {
    background-position: 50% 0%; }
  @media (min-width: 512px) {
    background-size: 300%;
    background-position: 50% 0%; }
  @media (min-width: 550px) {
    background-size: 270%; }
  @media (min-width: 600px) {
    background-size: 240%;
    background-position: 40% 0%; }
  @media (min-width: 768px) {
    background-size: 220%;
    background-position: 30% 0%; }
  @media (min-width: 992px) {
    background-size: 200%;
    background-position: 20% 0%; }
  @media (min-width: 1200px) {
    background-size: 150%; }
  @media (min-width: 1400px) {
    background-size: 130%;
    background-position: 15% 0%; }
  @media (min-width: 1600px) {
    background-size: 120%; }
  @media (min-width: 1800px) {
    background-size: 100%; } }

.fondo3 {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    background-image: url("https://e-client.s3.amazonaws.com/imagenes-tu-mejor-yo/2.jpg");
    background-size: 260%;
    background-repeat: no-repeat;
    background-position: 50% 0%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    @media (min-width: 375px) {
      background-size: 290%; }
    @media (min-width: 400px) {
      background-size: 265%;
      background-position: 50% 10%; }
    @media (min-width: 512px) {
      background-size: 250%;
      background-position: 50% 50%; }
    @media (min-width: 768px) {
      background-size: 190%;
      background-position: 50% 0%; }
    @media (min-width: 1000px) {
      background-size: 150%;
      background-position: 50% 0%; }
    @media (min-width: 1300px) {
      background-size: 130%;
      background-position: 15% 0%; }
    @media (min-width: 1600px) {
      background-size: 100%;
      background-position: 15% 0%; } } }

.fondo4 {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    background-image: url("https://e-client.s3.amazonaws.com/imagenes-tu-mejor-yo/6.png");
    background-size: 260%;
    background-repeat: no-repeat;
    background-position: 50% 0%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: .6;
    @media (min-width: 375px) {
      background-size: 290%; }
    @media (min-width: 400px) {
      background-size: 265%;
      background-position: 50% 10%; }
    @media (min-width: 512px) {
      background-size: 250%;
      background-position: 50% 50%; }
    @media (min-width: 768px) {
      background-size: 190%;
      background-position: 50% 0%; }
    @media (min-width: 1000px) {
      background-size: 150%;
      background-position: 50% 0%; }
    @media (min-width: 1300px) {
      background-size: 130%;
      background-position: 15% 0%; }
    @media (min-width: 1600px) {
      background-size: 100%;
      background-position: 15% 0%; } } }

.fondo5 {
  background-image: url("https://e-client.s3.amazonaws.com/imagenes-tu-mejor-yo/8.jpg");
  background-size: 260%;
  background-repeat: no-repeat;
  background-position: 50% 0%;
  @media (min-width: 375px) {
    background-size: 290%; }
  @media (min-width: 400px) {
    background-size: 265%;
    background-position: 50% 10%; }
  @media (min-width: 512px) {
    background-size: 250%;
    background-position: 50% 50%; }
  @media (min-width: 768px) {
    background-size: 190%;
    background-position: 50% 0%; }
  @media (min-width: 1000px) {
    background-size: 150%;
    background-position: 50% 0%; }
  @media (min-width: 1300px) {
    background-size: 130%;
    background-position: 15% 0%; }
  @media (min-width: 1600px) {
    background-size: 100%;
    background-position: 15% 0%; } }

.fondo6 {
  background-image: url("https://e-client.s3.amazonaws.com/imagenes-tu-mejor-yo/9.jpg");
  background-size: 260%;
  background-repeat: no-repeat;
  background-position: 50% 0%;
  @media (min-width: 375px) {
    background-size: 290%; }
  @media (min-width: 400px) {
    background-size: 265%;
    background-position: 50% 10%; }
  @media (min-width: 512px) {
    background-size: 250%;
    background-position: 50% 50%; }
  @media (min-width: 768px) {
    background-size: 190%;
    background-position: 50% 0%; }
  @media (min-width: 1000px) {
    background-size: 150%;
    background-position: 50% 0%; }
  @media (min-width: 1300px) {
    background-size: 130%;
    background-position: 15% 0%; }
  @media (min-width: 1600px) {
    background-size: 100%;
    background-position: 15% 0%; } }

.alturaCardInicio2 {
  padding-top: $mediano;
  @media (min-width: 992px) {
    height: 100%;
    padding-top: 20vw;
    text-align: left;
    padding-left: 25px;
    padding-right: 25px; }
  @media (min-width: 1250px) {
    padding-top: 13vw; }
  @media (min-width: 1550px) {
    padding-top: 10vw; } }

.posicionCard {
  position: absolute;
  z-index: 9999;
  width: 93%;
  padding-top: 100px;
  padding-left: 25px;
  padding-right: 25px;
  @media (min-width: 950px) {
    padding-left: 50px;
    padding-right: 50px; }
  @media (min-width: 1300px) {
    padding-left: 100px;
    padding-right: 100px;
    width: 96%; }
  @media (min-width: 1600px) {
    padding-left: 150px;
    padding-right: 150px; } }

.linkInicio {
  color: $gris !important;
  font-size: 21px;
  &:hover {
    color: $azul !important; }
  @media (min-width: 1100px) {
    font-size: 23px; }
  @media (min-width: 1200px) {
    font-size: 18px; }
  @media (min-width: 1300px) {
    font-size: 20px; } }

.opacity50 {
  opacity: .5; }

.areaOpacityClient {
  background-color: rgba(0,135,66,0.8);
  height: 420px;
  @media (min-width: 375px) {
    height: 500px; }
  @media (min-width: 414px) {
    height: 580px; }
  @media (min-width: 768px) {
    height: 800px; }
  @media (min-width: 890px) {
    height: 600px; } }

.areaOpacityClient2 {
  background-color: rgba(109,110,113,0.8);
  height: 70px;
  width: 110%;
  @media (min-width: 414px) {
    width: 107%; }
  @media (min-width: 768px) {
    height: 100px;
    width: 105%; }
  @media (min-width: 1000px) {
    height: 120px;
    width: 102%; } }

.contenedor2 {
  text-align: center; }


// SECCION 1
#cardInicio {
  position: absolute;
  margin: 0;
  width: 100%;
  top: 150px;
  padding-top: $enorme;
  text-align: center;
  @media (min-width: 400px) {
    top: 210px; }
  @media (min-width: 560px) {
    top: 100px; }
  @media (min-width: 750px) {
    top: 300px; }
  @media (min-width: 900px) {
    top: 350px; }
  @media (min-width: 1000px) {
    top: 280px; }
  @media (min-width: 1200px) {
    top: 320px; }
  @media (min-width: 1350px) {
    top: 350px; }
  @media (min-width: 1450px) {
    top: 380px; } }

.alturaCardInicio {
  padding-top: $mediano;
  width: 100%;
  @media (min-width: 400px) {
    padding-right: 8px; } }

.textoInicio {
  font-size: 27px;
  @media (min-width: 400px) {
    font-size: 30px; }
  @media (min-width: 560px) {
    font-size: 40px;
    line-height: 45px; }
  @media (min-width: 750px) {
    font-size: 48px;
    line-height: 55px; }
  @media (min-width: 900px) {
    font-size: 55px;
    line-height: 60px; }
  @media (min-width: 992px) {
    font-size: 59px;
    line-height: 66px; } }

.claseom {
  letter-spacing: 4px;
  @media (min-width: 560px) {
    letter-spacing: 6px; }
  @media (min-width: 900px) {
    letter-spacing: 12px; }
  @media (min-width: 992px) {
    letter-spacing: 12px; } }

.paddingInicio {
  padding-right: 15px; }

// SECCION 2

.letraEmpresas {
  font-size: 35px;
  line-height: 40px;
  @media (min-width: 576px) {
    font-size: 30px;
    line-height: 35px; }
  @media (min-width: 768px) {
    font-size: 5vw;
    line-height: 5.7vw; }
  @media (min-width: 800px) {
    font-size: 4.5vw;
    line-height: 5.2vw; }
  @media (min-width: 900px) {
    font-size: 4.3vw;
    line-height: 5vw; }
  @media (min-width: 1000px) {
    font-size: 4vw;
    line-height: 4.7vw; }
  @media (min-width: 1200px) {
    font-size: 45px;
    line-height: 50px; } }

.paddingEmpresas {
  padding-top: 2vw;
  padding-right: 2vw;
  padding-left: 2vw;
  @media (min-width: 576px) {
    padding-top: 1vw; }
  @media (min-width: 768px) {
    padding-top: 2vw; } }

.paddingSubtitulo {
  padding-top: 8px;
  @media (min-width: 768px) {
    padding-top: $mediano; } }

.paddingSubSubtitulo {
  padding-top: $chico;
  @media (min-width: 576px) {
    padding-top: $muychico; }
  @media (min-width: 768px) {
    padding-top: $chico; } }

.imagenEmpresas {
  object-fit: cover;
  width: 100%;
  height: 200px;
  @media (min-width: 576px) {
    height: 0px; }
  @media (min-width: 768px) {
    height: 270px; }
  @media (min-width: 1200px) {
    height: 23vw; } }

.letraSubtitulo {
  font-size: 20px;
  line-height: 22px;
  @media (min-width: 410px) {
    font-size: 22px;
    line-height: 26px; }
  @media (min-width: 576px) {
    font-size: 20px;
    line-height: 25px; }
  @media (min-width: 768px) {
    font-size: $tmuygrande;
    line-height: 30px; } }

.letraBullets {
  font-size: 17px;
  line-height: 21px;
  @media (min-width: 410px) {
    font-size: 19px;
    line-height: 23px; }
  @media (min-width: 576px) {
    font-size: 18px;
    line-height: 22px; }
  @media (min-width: 768px) {
    font-size: 23px;
    line-height: 26px; } }

.letraBulletsEmprendedor {
  font-size: 16px;
  line-height: 19px;
  @media (min-width: 410px) {
    font-size: 17px;
    line-height: 20px; }
  @media (min-width: 576px) {
    font-size: 16px;
    line-height: 19px; }
  @media (min-width: 768px) {
    font-size: 17px;
    line-height: 20px; }
  @media (min-width: 992px) {
    font-size: 18px;
    line-height: 22px; }
  @media (min-width: 1200px) {
    font-size: 19px;
    line-height: 23px; } }

.paddingBullets {
  padding-top: 5px;
  @media (min-width: 768px) {
    padding-top: $mediano; } }

.paddingBullets2 {
  padding-top: 0px; }

// SECCION 3
.letraContamos {
  font-size: 28px;
  line-height: 33px;
  padding-top: 30px;
  @media (min-width: 576px) {
    font-size: 27px;
    line-height: 31px; }
  @media (min-width: 768px) {
    padding-top: 40px;
    font-size: 30px;
    line-height: 45px; }
  @media (min-width: 900px) {
    padding-top: 50px; }
  @media (min-width: 1200px) {
    font-size: 45px;
    line-height: 50px; }
  @media (min-width: 1600px) {
    padding-top: 0px; } }


.letraAsesorando {
  font-size: 19px;
  line-height: 24px;
  @media (min-width: 576px) {
    font-size: 22px;
    line-height: 27px; }
  @media (min-width: 768px) {
    font-size: 25px;
    line-height: 29px; }
  @media (min-width: 1200px) {
    font-size: 30px;
    line-height: 34px; } }

// SECCION 5
.letraDesarrollo {
  font-size: 28px;
  padding-bottom: 5px;
  @media (min-width: 768px) {
    font-size: 35px;
    padding-bottom: 15px; }
  @media (min-width: 1350px) {
    font-size: 45px; }
  @media (min-width: 1650px) {
    font-size: 55px; } }

.letraDesarrollo2 {
  font-size: 30px;
  padding-bottom: 5px;
  padding-left: 20px;
  @media (min-width: 768px) {
    font-size: 35px;
    padding-bottom: 15px; }
  @media (min-width: 1350px) {
    font-size: 45px; }
  @media (min-width: 1650px) {
    font-size: 55px; } }

.letraClub {
  font-size: 35px;
  padding-left: 20px;
  @media (min-width: 768px) {
    font-size: 35px;
    padding-bottom: 15px; }
  @media (min-width: 1350px) {
    font-size: 45px; }
  @media (min-width: 1650px) {
    font-size: 55px; } }

.letraDesarrollo3 {
  font-size: 30px;
  @media (min-width: 768px) {
    font-size: 35px;
    padding-bottom: 15px; }
  @media (min-width: 1350px) {
    font-size: 40px; }
  @media (min-width: 1650px) {
    font-size: 50px; } }

.letraAprende {
  font-size: 18px;
  @media (min-width: 410px) {
    font-size: 20px; }
  @media (min-width: 1350px) {
    font-size: 25px; }
  @media (min-width: 1650px) {
    font-size: 28px; } }

.letraEmprendedor {
  font-size: 16px;
  @media (min-width: 410px) {
    font-size: 17px; }
  @media (min-width: 1350px) {
    font-size: 19px; }
  @media (min-width: 1600px) {
    font-size: 21px; } }

.fotoDesarrollo {
  width: 100%;
  height: 200px;
  object-fit: cover;
  @media (min-width: 576px) {
    padding-top: 15px;
    height: 360px; }
  @media (min-width: 768px) {
    height: 500px; }
  @media (min-width: 1350px) {
    height: 700px; }
  @media (min-width: 1650px) {
    height: 800px; } }

.fotoDesarrollo3 {
  width: 100%;
  height: 200px;
  object-fit: cover;
  @media (min-width: 576px) {
    padding-top: 15px;
    height: 360px; }
  @media (min-width: 768px) {
    height: 500px; }
  @media (min-width: 1350px) {
    height: 700px; }
  @media (min-width: 1650px) {
    height: 800px; } }

.fotoJovenes {
  width: 100%;
  height: 200px;
  object-fit: cover;
  @media (min-width: 576px) {
    padding-top: 15px;
    height: 180px; }
  @media (min-width: 768px) {
    height: 250px; }
  @media (min-width: 1350px) {
    height: 350px; }
  @media (min-width: 1650px) {
    height: 400px; } }

.fotoClub {
  width: 100%;
  height: 200px;
  object-fit: cover;
  @media (min-width: 576px) {
    padding-top: 15px;
    height: 360px; }
  @media (min-width: 768px) {
    height: 500px; }
  @media (min-width: 1350px) {
    height: 700px; }
  @media (min-width: 1650px) {
    height: 800px; } }

.fotoDesarrollo2 {
  width: 100%;
  height: 120px;
  object-fit: cover;
  @media (min-width: 576px) {
    padding-top: 15px;
    height: 360px; }
  @media (min-width: 768px) {
    height: 500px; }
  @media (min-width: 1350px) {
    height: 700px; }
  @media (min-width: 1650px) {
    height: 800px; } }

.paddingFotoDesarrollo {
  @media (min-width: 576px) {
    padding-top: 0px; }
  @media (min-width: 768px) {
    padding-left: 25px; }
  @media (min-width: 820px) {
    padding-top: 50px;
    padding-left: 50px; }
  @media (min-width: 992px) {
    padding-left: 100px; }
  @media (min-width: 1200px) {
    padding-left: 130px;
    padding-top: 50px; }
  @media (min-width: 1600px) {
    padding-top: 10px; } }

.paddingDesarrollo {
  padding-top: 0px;
  padding-left: 40px;
  @media (min-width: 576px) {
    padding-left: 10px; }
  @media (min-width: 768px) {
    padding-top: 10px; }
  @media (min-width: 992px) {
    padding-top: 90px; }
  @media (min-width: 1350px) {
    padding-top: 180px;
    padding-left: 35px; }
  @media (min-width: 1600px) {
    padding-top: 180px;
    padding-left: 60px; } }

.paddingDesarrollo2 {
  padding-top: 0px;
  padding-left: 20px;
  @media (min-width: 576px) {
    padding-left: 15px; }
  @media (min-width: 768px) {
    padding-top: 10px;
    padding-right: 40px; }
  @media (min-width: 992px) {
    padding-top: 15px;
    padding-right: 80px; }
  @media (min-width: 1350px) {
    padding-top: 120px;
    padding-right: 120px; }
  @media (min-width: 1600px) {
    padding-right: 160px; } }

.paddingBulletsJovenes {
  padding-left: 20px;
  @media (min-width: 576px) {
    padding-left: 20px; }
  @media (min-width: 1350px) {
    padding-left: 35px; }
  @media (min-width: 1600px) {
    padding-left: 60px; } }

.paddingTodoDesarrollo {
  padding-top: $mediano;
  @media (min-width: 576px) {
    padding-top: 0px; }
  @media (min-width: 768px) {
    padding-top: $chico; } }

.ppasion {
  padding-left: $grande;
  @media (min-width: 576px) {
    padding-left: 0px; } }

.columnaIzquierda {
  @media (min-width: 576px) {
    padding-right: 0px; } }

// SECCION 6
.fotoCristina {
  width: 100%;
  object-fit: cover;
  height: 280px;
  object-position: 100% 20%;
  @media (min-width: 576px) {
    object-position: 80% 0%;
    object-fit: cover;
    padding-top: 15px;
    height: 360px; }
  @media (min-width: 768px) {
    object-position: 60% 0;
    height: 500px; }
  @media (min-width: 1350px) {
    height: 700px; }
  @media (min-width: 1611px) {
    height: 800px;
    object-position: 60% 60%; } }

// SECCION 7
.cardCursos {
  position: absolute;
  padding-left: 20px;
  padding-right: 35px;
  margin: 0;
  top: 100px;
  padding-top: $enorme;
  text-align: center;
  @media (min-width: 576px) {
    width: 80%;
    top: 0px; }
  @media (min-width: 768px) {
    width: 60%;
    top: 170px; }
  @media (min-width: 992px) {
    top: 200px; }
  @media (min-width: 1100px) {
    top: 150px; }
  @media (min-width: 1600px) {
    top: 225px; } }

.alturaCardCursos {
  padding-top: $mediano; }

.textoCursos {
  font-size: 20px;
  padding-top: 10px;
  @media (min-width: 400px) {
    font-size: 24px; }
  @media (min-width: 560px) {
    font-size: 27px; }
  @media (min-width: 750px) {
    font-size: 30px; }
  @media (min-width: 900px) {
    font-size: 33px; }
  @media (min-width: 992px) {
    font-size: 36px; } }

.letraInscribete {
  @media (min-width: 992px) {
    font-size: 18px; } }

.paddingCursos {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 30px;
  padding-bottom: 30px; }

.centrado {
  position: relative;
  margin: auto !important;
  padding-left: 15px;
  padding-right: 0px; }

.inputEmail {
  background-color: transparent;
  color: white;
  border-radius: 0;
  border-bottom: 1px solid white;
  border-left: 0px;
  border-right: 0px;
  border-top: 0px;
  outline: none;
  &::placeholder {
    color: white; } }

.inputSelect {
  border-radius: 0px;
  outline: none;
  height: 23px;
  padding-bottom: 0px;
  padding-top: 1px;
  font-size: 15px;
  @media (min-width: 992px) {
    font-size: 20px;
    height: 30px; } }

.bottom {
  position: absolute;
  width: 100%;
  bottom: -147px;
  @media (min-width: 992px) {
    bottom: -157px; } }

.alturaCardCursos {
  padding-bottom: 150px; }

.pdeinput {
  padding-right: 12px; }

.tboton {
  font-size: 14px;
  @media (min-width: 992px) {
    font-size: $tgrande; } }

// SECCION 8

.paddingContacto {
  padding-right: 5vw;
  padding-left: 5vw;
  padding-top: 10vw;
  @media (min-width: 576px) {
    padding-top: 5vw; }
  @media (min-width: 768px) {
    padding-right: 10vw;
    padding-left: 10vw;
    padding-top: 100px; } }

.tletraContacto {
  font-size: 24px;
  @media (min-width: 375px) {
    font-size: 28px; }
  @media (min-width: 768px) {
    font-size: 32px; }
  @media (min-width: 992px) {
    font-size: 35px; }
  @media (min-width: 1100px) {
    font-size: 40px; }
  @media (min-width: 1600px) {
    font-size: 50px; } }

.tcontacto {
  padding-top: 15px;
  padding-left: 20px;
  font-size: 24px;
  border-top: 4px $azul solid;
  @media (min-width: 576px) {
    font-size: 30px;
    text-align: center;
    padding-top: 30px; }
  @media (min-width: 768px) {
    text-align: left;
    border-top: 0px;
    padding-top: 20px;
    padding-left: 50px;
    font-size: 22px;
    border-left: 4px $azul solid;
    padding-bottom: 500px; }
  @media (min-width: 850px) {
    font-size: 25px; }
  @media (min-width: 950px) {
    font-size: 28px; }
  @media (min-width: 1050px) {
    font-size: 32px; }
  @media (min-width: 1200px) {
    font-size: 40px; } }

.paddingContacto2 {
  @media (min-width: 768px) {
    padding-top: 250px; } }

.claseLogotipos {
  object-fit: cover;
  padding: 6px;
  width: 100px; }

.claseLogotipos2 {
  object-fit: contain;
  padding: 6px;
  width: 100px;
  height: 140px; }

.claseLogotipos3 {
  object-fit: contain;
  padding: 6px;
  width: 100px;
  height: 120px; }

.centroDesarrolloHumano {
  text-align: center;
  align-items: center;
  @media (min-width: 992px) {
    padding-top: 5px;
    text-align: left;
    align-items: left; } }

.tchica2 {
  font-size: 14px;
  @media (min-width: 500px) {
    font-size: 15px; } }

.tchica3 {
  font-size: 13px;
  @media (min-width: 500px) {
    font-size: 14px; } }

.tprofesion {
  width: 180px;
  left: 50px;
  @media (min-width: 500px) {
    width: 200px;
    font-size: 16px; } }

.trally {
  width: 70px;
  left: 50px;
  @media (min-width: 576px) {
    width: 110px; }
  @media (min-width: 768px) {
    width: 140px;
    font-size: 16px; }
  @media (min-width: 992px) {
    width: 155px; }
  @media (min-width: 1200px) {
    width: 150px; }
  @media (min-width: 1350px) {
    width: 160px; } }

.pjovenes {
  padding-left: 15px; }



.paddingTaller {
  @media (min-width: 576px) {
    padding-top: 5px; }
  @media (min-width: 768px) {
    padding-top: 30px; }
  @media (min-width: 992px) {
    padding-top: 35px; } }

.paddingClub {
  @media (min-width: 992px) {
    padding-bottom: 35px; } }

.paddingJovenes {
  padding-top: 0px;
  padding-left: 20px;
  @media (min-width: 576px) {
    padding-left: 15px; }
  @media (min-width: 768px) {
    padding-top: 10px;
    padding-right: 40px; }
  @media (min-width: 992px) {
    padding-right: 80px; }
  @media (min-width: 1350px) {
    padding-top: 10px;
    padding-right: 120px; }
  @media (min-width: 1600px) {
    padding-right: 160px; } }

.paddingMision {
  @media (min-width: 576px) {
    padding-top: 5px; }
  @media (min-width: 768px) {
    padding-top: 70px; }
  @media (min-width: 992px) {
    padding-top: 70px; }
  @media (min-width: 1350px) {
    padding-top: 170px; }
  @media (min-width: 1550px) {
    padding-top: 200px; }
  @media (min-width: 1650px) {
    padding-top: 220px; }
  @media (min-width: 1700px) {
    padding-top: 240px; } }

// Navbar

.fondoBlancoNav {
  background-color: $blanco;
  @media (min-width: 1200px) {
    background-color: transparent; } }

.fondoBlancoNav2 {
  z-index: 999;
  height: 100px;
  @media (min-width: 768px) {
    height: 120px; }
  @media (min-width: 1200px) {
    height: 150px; } }

.inicioEmpresas {
  padding-top: 70px; }

.inicioEmprendimiento {
  padding-top: 70px;
  @media (min-width: 1200px) {
    padding-top: 0px; }
  @media (min-width: 1600px) {
    padding-top: 0px; } }

.inicioNavbar {
  @media (min-width: 1200px) {
    padding-top: 0px; }
  @media (min-width: 1600px) {
    padding-top: 0px; } }

